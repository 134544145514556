
import { Vue, Component  } from "vue-property-decorator";
import { EmpreendimentoService, PessoaService } from "@/core/services/cadastros";
import { ContaGerencialService, FormaPagamentoService } from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgDespesa extends Vue {
    filter: {
      tipoId:string;
      empreendimentoId: any;
      formaPagamento:string;
      fornecedorId:string;
      dataVencimentoInicial:string;
      dataVencimentoFinal:string;
      dataBaixaInicial:string;
      dataBaixaFinal:string;
      dataEmissaoInicial:string;
      dataEmissaoFinal:string;
      empresaId:any,
      contaGerencialId:any
    } = {
    tipoId:"",
    empreendimentoId: 0,
    formaPagamento:"",
    fornecedorId:"",
    dataVencimentoInicial:"",
    dataVencimentoFinal:"",
    dataBaixaInicial:"",
    dataBaixaFinal: "",
    dataEmissaoInicial: "",
    dataEmissaoFinal: "",
    empresaId: 0,
    contaGerencialId:0,
   };
  
  /* EMPRESAS */
  SelecionarEmpresa(){
    this.$nextTick(() => {
      if (this.selecionarTodosEmpresas) {
        this.filter.empresaId = []
      } else {
        this.filter.empresaId = this.empresas.slice().map((x:any)=>x.id)
      }
    })
  }

  get selecionarTodosEmpresas(){
    return this.filter.empresaId.length === this.empresas.length
  }

  get SelecionarAlgunsEmpresas(){
    return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresas
  }

  get iconEmpresa(){
    if (this.selecionarTodosEmpresas) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpresa(){
    if (this.selecionarTodosEmpresas) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
    return 'Selecionar todos'
  } 
    /* TIPO DE RECEITA  */
    get iconEmpreendimento(){
      if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
    
    get textEmpreendimento(){
      if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
      return 'Selecionar todos'
    }

    SelecionarEmpreendimento() {
      this.$nextTick(() => {
          if (this.selecionarTodosEmpreendimentos) {
          this.filter.empreendimentoId = []
          } else {
            this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
          }
      })
    }
    get selecionarTodosEmpreendimentos(){
      return this.filter.empreendimentoId.length === this.empreendimentos.length
    }
    get SelecionarAlgunsEmpreendimentos(){
      return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
    }


    valid = true;
    pessoas = [];
    formaPagamento:any = [];
    empreendimentos: any = [];
    empresas:any = [];
    listaContaGerencial = [];
    listaCentroCustoXContaGerencial = [{id:1, nome:"Centro de Custo"}, {id:2, nome:"Conta Gerencial"}]

  mounted() {
 
    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => { 
        this.empresas = res.data.items
      }
    )
 
    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      (res) => { 
        this.empreendimentos = res.data.items
      }
    );

    new FormaPagamentoService().ListarTudo().then(
      res => { 
      this.formaPagamento = res.data.items
      }
    )

    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      res => {
        this.pessoas = res.data.items
      }
    )

    new ContaGerencialService().Listagem().then((res) => {
      this.listaContaGerencial = res.data;
    });
  } 

  Visualizar() {
    if(!this.filter.tipoId){
      this.$swal("Aviso", "Obrigatório preencher o tipo se é Centro de Custo ou Conta Gerencial", "warning");
      return true;
    }

    if((!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal) && (!this.filter.dataBaixaInicial || !this.filter.dataBaixaFinal) && (!this.filter.dataEmissaoInicial || !this.filter.dataEmissaoFinal)){
      this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
      return true;
    }
   
    
    let routeLink = this.$router.resolve({
      name: "relDespesaRateada", 
      query: { 
        tipoId: this.filter.tipoId ? this.filter.tipoId.toString() : "",
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
        fornecedorId: this.filter.fornecedorId ? this.filter.fornecedorId : "",
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : "",
        formaPagamentoId: this.filter.formaPagamento ? this.filter.formaPagamento : "",
        dataVencimentoInicial:this.filter.dataVencimentoInicial ? this.filter.dataVencimentoInicial: "",
        dataVencimentoFinal: this.filter.dataVencimentoFinal ? this.filter.dataVencimentoFinal: "",
        dataBaixaInicial:this.filter.dataBaixaInicial ? this.filter.dataBaixaInicial: "",
        dataBaixaFinal:this.filter.dataBaixaFinal ? this.filter.dataBaixaFinal: "",
        dataEmissaoInicial:this.filter.dataEmissaoInicial ? this.filter.dataEmissaoInicial: "",
        dataEmissaoFinal:this.filter.dataEmissaoFinal ? this.filter.dataEmissaoFinal: "",
        contaGerencialId: this.filter.contaGerencialId ? this.filter.contaGerencialId.toString() : "",
      },
    });
    window.open(routeLink.href, "_blank");
  }
}
